import React from 'react';
import {
  Menu,
  MenuItemLink,
  useGetIdentity,
  useSidebarState,
  useGetList,
  useTranslate,
  MenuProps,
  useStore,
} from 'react-admin';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { useTheme, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import TocOutlinedIcon from '@mui/icons-material/TocOutlined';
import Tooltip from '@mui/material/Tooltip';
import 'devextreme/dist/css/dx.light.css';
import AddMenuButton from '../../buttons/addMenuButton/AddMenuButton';
import './CustomMenu.css';
import { APP_VERSION, MENU_CLOSED_WIDTH, MENU_OPENED_WIDTH } from '../../../utils/constants';
import { IApproval } from '../../../types/types';
import { getApprovalStatus } from '../../../utils/utils';
import CustomModal from '../../modals/customModal/CustomModal';
import LicErrorModal from '../../modals/licErrorModal/LicErrorModal';
import { fetchProvider } from '../../../provider/authProvider';

const CustomMenu = (props: MenuProps) => {
  const [open] = useSidebarState();
  const [settings] = useStore('settings');
  const [approvals, setApprovals] = React.useState<number>();
  const [showAdministration, setShowAdministration] = React.useState(false);
  const [showLicErrorModal, setShowLicErrorModal] = React.useState(false);
  const { identity: user } = useGetIdentity();
  const translate = useTranslate();
  const theme = useTheme();
  const { pathname, search } = useLocation();

  React.useEffect(() => {
    fetchProvider('/api/settings/lic')
      .then((res) => {
        if (res.status === 200) return res.json();
        else throw new Error();
      })
      .then((result) => {
        const licLocal = localStorage.getItem('lic');
        const isError = result.attributes && result.attributes.activeUserCount >= result.attributes.licCount;
        if (licLocal && !JSON.parse(licLocal)?.show && isError) setShowLicErrorModal(true);
        localStorage.setItem(
          'lic',
          JSON.stringify({
            show: Boolean(JSON.parse(licLocal || '{}')?.show),
            err: isError,
          }),
        );
      });
  }, [pathname, search]);

  const onToggleAdministrationItem = () => {
    setShowAdministration((prev) => !prev);
  };

  useGetList(
    'approval',
    { filter: { status: 'pending' }, pagination: { page: 1, perPage: 1000 } },
    {
      onSuccess: (data) => {
        setApprovals(
          data.data.filter(
            (approval: IApproval) => approval.ownerId !== null && getApprovalStatus(approval) === 'pending',
          ).length,
        );
      },
      onError: () => false,
    },
  );

  const closeLicErrorModal = () => {
    setShowLicErrorModal(false);
    localStorage.setItem('lic', JSON.stringify({ show: true, err: true }));
  };

  const MenuItemApprovals = () => (
    <div className={'menu_item approval'}>
      <span>{translate('operations.entities.APPROVAL')}</span>
      {approvals && approvals > 0 ? (
        <div className={'menu_item__count'}>
          <span>{approvals}</span>
        </div>
      ) : null}
    </div>
  );

  const ApprovalsMinIcon = () => (
    <div className={'approvals_icon'}>
      <div className={'menu_item__icon'}>
        <RestoreOutlinedIcon />
      </div>
      {approvals && approvals > 0 ? (
        <div className={'approvals_icon__count'}>
          <span>{approvals}</span>
        </div>
      ) : null}
    </div>
  );

  if (!user) return null;

  return (
    <div className="menu_wrapper">
      <Menu
        {...props}
        sx={{
          '&.RaMenu-open': {
            width: MENU_OPENED_WIDTH,
          },
          '&.RaMenu-closed': {
            width: MENU_CLOSED_WIDTH,
          },
          '& a': {
            color: theme.palette.text.secondary,
            '&:hover': {
              color: theme.palette.primary.dark,
            },
          },
          '& .RaMenuItemLink-active': {
            '& svg': {
              fill: theme.palette.text.primary,
            },
          },
          '& .line': {
            backgroundColor: theme.palette.text.secondary,
          },
        }}
      >
        <AddMenuButton isCollapse={!open} user={user} />
        <MenuItemLink
          to={`/profile/${user?.id}/show`}
          primaryText={translate('operations.entities.MYPROFILE')}
          leftIcon={
            <div className={'menu_item__icon'}>
              <AccountCircleOutlinedIcon />
            </div>
          }
          sx={{ paddingLeft: '24px' }}
        />
        <MenuItemLink
          to={'/app'}
          primaryText={translate('operations.entities.APP')}
          leftIcon={
            <div className={'menu_item__icon'}>
              <WidgetsOutlinedIcon />
            </div>
          }
          sx={{ paddingLeft: '24px' }}
        />
        <Menu.Item
          to={'/approval'}
          primaryText={translate('operations.entities.APPROVAL')}
          leftIcon={
            open ? (
              <div className={'menu_item__icon'}>
                <RestoreOutlinedIcon />
              </div>
            ) : (
              <ApprovalsMinIcon />
            )
          }
          sx={{ paddingLeft: '24px' }}
        >
          <MenuItemApprovals />
        </Menu.Item>
        {[
          <div className={'line'} key={'line'} />,
          user.role === 1 ? (
            <MenuItemLink
              to={'/user'}
              key={'user'}
              primaryText={translate('operations.entities.USER')}
              leftIcon={
                <div className={'menu_item__icon'}>
                  <PermIdentityOutlinedIcon />
                </div>
              }
              sx={{ paddingLeft: '24px' }}
            />
          ) : null,
          user.role === 1 ? (
            <MenuItemLink
              to={'/group'}
              key={'group'}
              primaryText={translate('operations.entities.GROUP')}
              leftIcon={
                <div className={'menu_item__icon'}>
                  <PeopleAltOutlinedIcon />
                </div>
              }
              sx={{ paddingLeft: '24px' }}
            />
          ) : null,
          user.role === 1 ? (
            !open ? (
              <Tooltip title={translate('operations.entities.ADMINISTRATION')} placement={'right'}>
                <Box
                  key={'wrapper'}
                  sx={{
                    '& .menu_item': {
                      '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                      },
                    },
                    '& .MuiSvgIcon-root': {
                      fill: theme.palette.action.active,
                    },
                  }}
                >
                  <div className={'menu_item menu_item__wrapper'} onClick={onToggleAdministrationItem}>
                    <div className={'menu_item__icon'}>
                      <SettingsOutlinedIcon />
                    </div>
                  </div>
                </Box>
              </Tooltip>
            ) : (
              <Box
                key={'wrapper'}
                sx={{
                  '& .menu_item': {
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                      '& .menu_item__text': {
                        color: theme.palette.primary.dark,
                      },
                    },
                  },
                  '& .MuiSvgIcon-root': {
                    fill: theme.palette.action.active,
                  },
                }}
              >
                <div className={'menu_item menu_item__wrapper'} onClick={onToggleAdministrationItem}>
                  <div className={'menu_item__icon'}>
                    <SettingsOutlinedIcon />
                  </div>
                  {open ? <a className={'menu_item__text'}>{translate('operations.entities.ADMINISTRATION')}</a> : null}
                </div>
              </Box>
            )
          ) : null,
          user.role === 1 && showAdministration ? (
            <MenuItemLink
              to={'/connector'}
              key={'connector'}
              primaryText={translate('operations.entities.CONNECTORS')}
              leftIcon={
                <div className={'menu_item__icon'}>
                  <InsertLinkOutlinedIcon />
                </div>
              }
              sx={{
                paddingLeft: '24px',
                '& .MuiListItemIcon-root': {
                  '& img': {
                    width: 24,
                    height: 24,
                  },
                },
              }}
            />
          ) : null,
          user.role === 1 && showAdministration ? (
            <MenuItemLink
              to={'/fields'}
              key={'fields'}
              primaryText={translate('operations.entities.PROFILE_FIELDS')}
              leftIcon={
                <div className={'menu_item__icon'}>
                  <ManageAccountsOutlinedIcon />
                </div>
              }
              sx={{
                paddingLeft: '24px',
              }}
            />
          ) : null,
          <MenuItemLink
            to={'/operation'}
            key={'operation'}
            primaryText={translate('operations.entities.EVENTS')}
            leftIcon={<TocOutlinedIcon />}
            sx={{ paddingLeft: '24px' }}
          />,
        ].map((elem) => elem)}
      </Menu>
      {open ? (
        <div className="versions">
          <span>{translate('organisation.language')}</span>
          <span>{`${translate('organisation.lkVersion')} ${APP_VERSION}`}</span>
          <span>{`${translate('organisation.apiVersion')} ${settings ? JSON.parse(settings).api.version : null}`}</span>
          <span>{settings ? JSON.parse(settings).app.settings.APP_COPYRIGHT : null}</span>
        </div>
      ) : null}
      <CustomModal open={showLicErrorModal && user.role === 1} close={closeLicErrorModal}>
        <LicErrorModal closeModal={closeLicErrorModal} />
      </CustomModal>
    </div>
  );
};

export default CustomMenu;
