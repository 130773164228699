import React from 'react';
import { useStore } from 'react-admin';
import RemoveUserFromGroupModal from '../removeUserFromGroupModal/RemoveUserFromGroupModal';
import CustomModal from '../customModal/CustomModal';
import RemoveGroupFromGroupModal from '../removeGroupFromGroupModal/RemoveGroupFromGroupModal';

export const ModalWrapper = () => {
  const [state, setState] = useStore('modal', JSON.stringify({}));
  const [showExcludeModal, setShowExcludeModal] = React.useState(false);
  const [showExcludeGroupModal, setShowExcludeGroupModal] = React.useState(false);

  React.useEffect(() => {
    const obj = JSON.parse(state);
    if (obj?.name === '') {
      setShowExcludeModal(false);
      setShowExcludeGroupModal(false);
    }
    switch (obj?.name) {
      case 'removeUserFromGroup': {
        setShowExcludeModal(JSON.parse(state).show);
        break;
      }
      case 'removeGroupFromGroup': {
        setShowExcludeGroupModal(JSON.parse(state).show);
        break;
      }
      default:
        break;
    }
  }, [state]);

  const onCloseModal = () => {
    setState(JSON.stringify({ name: '', show: false }));
  };

  return (
    <div style={{ position: 'absolute' }}>
      <CustomModal open={showExcludeModal} close={onCloseModal}>
        <RemoveUserFromGroupModal
          closeModal={onCloseModal}
          user={JSON.parse(state).record}
          selectedIds={JSON.parse(state).selectedIds}
          groupId={JSON.parse(state).groupId}
        />
      </CustomModal>
      <CustomModal open={showExcludeGroupModal} close={onCloseModal}>
        <RemoveGroupFromGroupModal
          closeModal={onCloseModal}
          group={JSON.parse(state).group}
          record={JSON.parse(state).record}
          selectedIds={JSON.parse(state).selectedIds}
          context={JSON.parse(state).context}
        />
      </CustomModal>
    </div>
  );
};
