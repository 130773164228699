import { useNavigate } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import styles from './AddProfileMenu.module.css';
import CustomPopoverMenuItem from '../customPopoverMenuItem/CustomPopoverMenuItem';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

type AddProfileMenuProps = {
  closePopover: () => void;
  isAdmin: boolean;
};

const AddProfileMenu = (props: AddProfileMenuProps) => {
  const { closePopover, isAdmin } = props;
  const blockCreateUser = Boolean(JSON.parse(localStorage.getItem('lic') || '{}')?.err);
  const navigate = useNavigate();
  const translate = useTranslate();
  const onClickWrapper = (e: React.SyntheticEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const createCounterparty = () => {
    if (!blockCreateUser) {
      navigate('/user/create');
      closePopover();
    }
  };

  const createApp = () => {
    navigate('/app/create');
    closePopover();
  };

  const createGroup = () => {
    navigate('/group/create');
    closePopover();
  };

  return (
    <div className={styles.root} onClick={onClickWrapper}>
      <div className={styles.middle}>
        <CustomPopoverMenuItem
          text={translate('operations.params.USER.ofUser')}
          onClick={createCounterparty}
          icon={<PersonAddAltOutlinedIcon />}
          disable={blockCreateUser}
        />
        {isAdmin ? (
          <CustomPopoverMenuItem
            text={translate('operations.params.APP.application')}
            onClick={createApp}
            icon={<WidgetsOutlinedIcon />}
          />
        ) : null}
        {isAdmin ? (
          <CustomPopoverMenuItem
            text={translate('operations.params.GROUP.ofGroup')}
            onClick={createGroup}
            icon={<PeopleAltOutlinedIcon />}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AddProfileMenu;
