import { fetchUtils, AuthProvider } from 'react-admin';
const httpClient = fetchUtils.fetchJson;
const authOptions = {
  user: {
    authenticated: true,
    token: localStorage.getItem('token') || undefined,
  },
};

const authProvider: AuthProvider = {
  login: ({ username, password }) => {
    return new Promise((res: any, rej) => {
      httpClient('/api/login', {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      })
        .then(({ json }) => {
          localStorage.setItem('username', json.userId);
          fetchProvider('/api/settings/lic')
            .then((res) => {
              if (res.status === 200) return res.json();
              else throw new Error();
            })
            .then((result) => {
              localStorage.setItem(
                'lic',
                JSON.stringify({
                  show: false,
                  err: result.attributes && result.attributes.activeUserCount >= result.attributes.licCount,
                }),
              );
              res();
            });
        })
        .catch(() => rej());
    });
  },
  logout: () => {
    return new Promise((res: any, rej) => {
      httpClient('/api/logout', { method: 'GET' })
        .then(() => {
          localStorage.removeItem('username');
          localStorage.removeItem('token');
          localStorage.removeItem('lic');
          localStorage.removeItem('settings');
          res();
        })
        .catch((error: any) => {
          rej(error);
        });
    });
  },
  checkError: (params: any) => {
    const status = params.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('username');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: (params: any) => {
    if (localStorage.getItem('username')) return Promise.resolve();

    if (params.context === 'local') return Promise.resolve();
    return new Promise((res: any, rej) => {
      httpClient('/api/profile', { method: 'GET', ...authOptions })
        .then(({ json }) => {
          if (!json.email || !json.email.length) window.location.href = '/#/confirm_email';
          localStorage.setItem('username', json.id);
          res();
        })
        .catch(() => rej());
    });
  },
  getPermissions: () => Promise.reject('Unknown method'),
  getIdentity: () => {
    return new Promise((res: any) => {
      httpClient('/api/profile', { method: 'GET', ...authOptions })
        .then(({ json }) => {
          res({
            id: json.id,
            avatar: json.photo,
            roles: json.roles,
            resources: json.resources,
            role: json.role,
            memberOf: json.memberOf,
            email: json.email,
            name: `${json.surname ?? ''} ${json.givenName ?? ''}`,
            appAdmins: json.appAdmins,
          });
        })
        .catch((e) => {
          if (e.status === 401 || e.status === 403) window.location.href = '/api/auth';
          res();
        });
    });
  },
};

export default authProvider;

export const fetchProvider = (path: string, method?: string, body?: any) => {
  try {
    const token = localStorage.getItem('token');
    return fetch(path, {
      method: method || 'GET',
      body: JSON.stringify(body),
      headers: (() => {
        const headers = new Headers({
          'Content-Type': 'application/json',
        });
        if (token?.trim()) headers.append('Authorization', token!);
        return headers;
      })(),
    });
  } catch (e) {
    return Promise.reject();
  }
};
